import {
  DialogActions,
  DialogTitle,
  Stack
} from "@mui/material";
import {trans} from "../../utils/translation";
import DialogContent from "@mui/material/DialogContent";
import {useModalContext} from "../../context/use-modal-context";
import useSubmit from "../../hooks/use-submit";
import {FormProvider} from "../../context/use-form-context";
import InertiaRemoteAutocomplete from "../../components/form/inertia-remote-autocomplete";
import {LoadingButton} from "@mui/lab";
import CustomButton from "../../components/custom-button";
import { useForm } from '@/hooks/use-form'
import PropTypes from "prop-types";
import Roles from "../../constants/Roles";
import InertiaAutocomplete from "../../components/form/inertia-autocomplete";
import UserState from "../../constants/UserState";
import PropertyState from "../../constants/PropertyState";
import {ToastCtl} from "../../utils/toast-ctl";
import Typography from "@mui/material/Typography";
import {useEffect, useState} from "react";
import Iconify from "../../components/iconify";

export default function MailRecipientModal ({ setRecipients, recipients }) {

  const items=[
      { id: true, label: trans('general.yes') },
      { id: false, label: trans('general.no') },
    ]

  const { closeModal } = useModalContext()

  const methods = useForm({
    url: {
      method: 'PUT',
      location: '/api/mails/recipient',
    },
    defaultValues: {
      user: [],
      role: [],
      isContractor: null,
      notifyViaEmail: null,
      newsletter: null,
      userState: [],
      propertyState: [],
      property: [],
    },
  })

  const { submit, submitting } = methods
  const [recipientCount, setRecipientCount] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    submit((response) => {
      if(response.data) {
        if(response.data['hydra:member'].length === 0) {
          ToastCtl.show('Keine Empfänger gefunden', 'warning')
        }
        const allRecipients =  [...recipients, ...response.data['hydra:member']]
        // remove duplicates
        const uniqueRecipients = allRecipients.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i)
        setRecipients(uniqueRecipients)
        closeModal('mailRecipient')
      }
    })
  }
  const fetchRecipientCount = async () => {
    setLoading(true)
    try {
      const response = await methods.validate()
      setRecipientCount(response.data)
      setLoading(false)
    } catch (error) {
      setRecipientCount(0)
    }
  };

  useEffect(() => {
    fetchRecipientCount()
  }, [methods.data])

  useSubmit(() => handleSubmit())

  return (
    <>
      <DialogTitle> {trans('mail.add_recipient')}</DialogTitle>
      <Typography variant={'subtitle2'} sx={{ px: 3}}>
        Filter Sie Nutzer mit:
      </Typography>
      <DialogContent dividers sx={{ py: 1 }}>
        <FormProvider {...methods} sx={{ px: 2, mx: 8 }}>
          <form onSubmit={handleSubmit}>
            <Stack direction={'column'} spacing={2}>
              <InertiaAutocomplete
                name={'role'}
                input={{
                  label: trans('field.role'),
                  multiple: true
                }}
                items={Object.values(Roles).map((role) => ({ id: role, label: trans(`role.${role}`) }))}
              />
              <InertiaAutocomplete
                name={'userState'}
                input={{
                  label: trans('mail.filter.state'),
                  multiple: true
                }}
                items={Object.values(UserState).map((state) => ({ id: state, label: trans(`state.user.${state}`) }))}
              />
              <InertiaAutocomplete
                name={'propertyState'}
                input={{
                  label: trans('mail.filter.property_state'),
                  multiple: true
                }}
                items={Object.values(PropertyState).map((state) => ({ id: state, label: trans(`state.property.${state}`) }))}
              />
              <InertiaAutocomplete
                name={'isContractor'}
                input={{
                  label: 'Ist Vertragspartner'
                }}
                items={items}
                />
              <InertiaAutocomplete
                name={'notifyViaEmail'}
                input={{
                  label: trans('user.functions.email_notification')
                }}
                items={items}
              />
              <InertiaAutocomplete
                name={'newsletter'}
                input={{
                  label: trans('mail.filter.newsletter')
                }}
                items={items}
              />
              <InertiaRemoteAutocomplete
                name={'property'}
                input={{
                  label: 'Immobilie',
                  multiple: true
                }}
                url={'/api/properties?name=%s&itemsPerPage=30'}
                />
              <InertiaRemoteAutocomplete
                name={'user'}
                input={{
                  label: 'Person/en',
                  tooltip: trans('general.not_all_users', { count: 30 }),
                  multiple: true
                }}
                url={`/api/users?name=%s&itemsPerPage=30`}
              />
              <Typography variant={'subtitle2'}>
                {loading &&
                  <Stack direction={'row'} spacing={1} alignItems={'center'}>
                    <Typography variant={'subtitle2'}>Lade Empfänger</Typography>
                    <Iconify icon={'svg-spinners:3-dots-move'} sx={{ ml: 1 }} />
                  </Stack>
                }
                {recipientCount !== null && !loading &&
                  <Typography variant={'subtitle2'}>Betrifft {recipientCount} E-Mail-Adressen</Typography>
                }
              </Typography>
            </Stack>
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <CustomButton onClick={() => closeModal('mailRecipient')}>{trans('button.cancel')}</CustomButton>
        <LoadingButton
          loading={submitting}
          variant={'contained'}
          onClick={() => handleSubmit()}
        >
          {trans('button.add')}
        </LoadingButton>
      </DialogActions>
    </>
  )
}

MailRecipientModal.propTypes = {
  setRecipients: PropTypes.func,
  recipients: PropTypes.array
}