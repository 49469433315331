import * as React from 'react'
import {useEffect, useMemo, useState} from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import IconButton from '@mui/material/IconButton'
import {InfoOutlined, Lock} from '@mui/icons-material'
import {Tooltip, useTheme} from '@mui/material'
import {get} from "lodash"
import {useFormContext} from "../../context/use-form-context";
import {StyledEditor} from "../../theme/overrides/components/richtext";


const InertiaRichtext = ({ input, name }) => {
  const { rules, data, setDataDebounce, overrideFieldValues, errors } = useFormContext()
  const { label, placeholder, readOnly, tooltip, required } = input
  const theme = useTheme()

  const [fieldValue, setFieldValue] = useState(get(data, name))

  useEffect(() => {
    const override = get(overrideFieldValues, name)
    if(typeof override !== 'undefined') {
      setFieldValue(override)
    }
  }, [get(overrideFieldValues, name)])


  const onEditorStateChange = (editorState) => {
    setFieldValue(editorState)
    setDataDebounce(name, editorState)
  }

  return useMemo(() => {
    return (
      <StyledEditor>
        <div className="ql-custom-container" >
              <span className="ql-custom-wrapper"
                    style={{color: get(errors, name) ? '#d32f2f' : (theme.palette.mode === 'dark' ? '#fff' : '#0009')}}>
                  {`${label} ${(required || rules[name]?.indexOf('required') > -1) ? ' *' : ''}`}
              </span>

          <div className={`ql-toolbar-${name.replaceAll('.', '')}`} style={{display: 'none'}}>
              <span className="ql-formats">
                      <button type="button" className="ql-bold"></button>
                      <button type="button" className="ql-italic"></button>
                      <button type="button" className="ql-underline"></button>
                      <button type="button" className="ql-image"></button>
              </span>
            <span className="ql-formats">
                      <button type="button" className="ql-list" value="bullet"></button>
                      <button type="button" className="ql-list" value="ordered"></button>
            </span>
            <span className="ql-formats">
                      <button type="button" className="ql-indent" value="-1"></button>
                      <button type="button" className="ql-indent" value="+1"></button>
                  </span>
            <span className="ql-formats">
                      <button type="button" className="ql-link"></button>
                      <button type="button" className="ql-clean"></button>
                  </span>
            <span className="ql-formats">
                      <button className="ql-insertPageBreak">
                           <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                              <path className="ql-even ql-stroke"
                                    d="M2.5 12C2.5 11.5858 2.83579 11.25 3.25 11.25H4.75C5.16421 11.25 5.5 11.5858 5.5 12C5.5 12.4142 5.16421 12.75 4.75 12.75H3.25C2.83579 12.75 2.5 12.4142 2.5 12Z"
                                    fill="#212121"/>
                              <path className="ql-even ql-stroke"
                                    d="M6.5 12C6.5 11.5858 6.83579 11.25 7.25 11.25H8.75C9.16421 11.25 9.5 11.5858 9.5 12C9.5 12.4142 9.16421 12.75 8.75 12.75H7.25C6.83579 12.75 6.5 12.4142 6.5 12Z"
                                    fill="#212121"/>
                              <path className="ql-even ql-stroke"
                                    d="M10.5 12C10.5 11.5858 10.8358 11.25 11.25 11.25H12.75C13.1642 11.25 13.5 11.5858 13.5 12C13.5 12.4142 13.1642 12.75 12.75 12.75H11.25C10.8358 12.75 10.5 12.4142 10.5 12Z"
                                    fill="#212121"/>
                              <path className="ql-even ql-stroke"
                                    d="M14.5 12C14.5 11.5858 14.8358 11.25 15.25 11.25H16.75C17.1642 11.25 17.5 11.5858 17.5 12C17.5 12.4142 17.1642 12.75 16.75 12.75H15.25C14.8358 12.75 14.5 12.4142 14.5 12Z"
                                    fill="#212121"/>
                              <path className="ql-even ql-stroke"
                                    d="M18.5 12C18.5 11.5858 18.8358 11.25 19.25 11.25H20.75C21.1642 11.25 21.5 11.5858 21.5 12C21.5 12.4142 21.1642 12.75 20.75 12.75H19.25C18.8358 12.75 18.5 12.4142 18.5 12Z"
                                    fill="#212121"/>
                              <path className="ql-even ql-stroke"
                                    d="M4.75 2C4.33579 2 4 2.33579 4 2.75V7C4 8.104 4.896 9 6 9H18C19.104 9 20 8.104 20 7V2.75C20 2.33579 19.6642 2 19.25 2C18.8358 2 18.5 2.33579 18.5 2.75V7C18.5 7.275 18.276 7.5 18 7.5H6C5.724 7.5 5.5 7.275 5.5 7V2.75C5.5 2.33579 5.16421 2 4.75 2Z"
                                    fill="#212121"/>
                              <path className="ql-even ql-stroke"
                                    d="M19.25 22C19.6642 22 20 21.6642 20 21.25V17C20 15.896 19.104 15 18 15L6 15C4.896 15 4 15.896 4 17L4 21.25C4 21.6642 4.33579 22 4.75 22C5.16421 22 5.5 21.6642 5.5 21.25L5.5 17C5.5 16.725 5.724 16.5 6 16.5L18 16.5C18.276 16.5 18.5 16.725 18.5 17V21.25C18.5 21.6642 18.8358 22 19.25 22Z"
                                    fill="#212121"/>
                          </svg>
                      </button>
                  </span>
          </div>
          <ReactQuill
            theme="snow"
            value={fieldValue}
            onChange={onEditorStateChange}
            modules={{
              toolbar: [
                [{header: [1, 2, false]}],
                ['bold', 'italic', 'underline'],
                ['image', 'link', 'clean'],
                [{list: 'ordered'}, {list: 'bullet'}],
                [{align: []}],
              ],
            }}
            placeholder={placeholder}
            readOnly={readOnly}
            className={get(errors, name) ? 'validation-error' : ''}
          />
          {tooltip && <>
            <Tooltip title={tooltip} sx={{position: 'absolute', right: 0, top: '10px'}}>
              <IconButton>
                <InfoOutlined/>
              </IconButton>
            </Tooltip>
          </>}
          {readOnly && <>
            <Tooltip title={'Bearbeitung deaktiviert'} sx={{position: 'absolute', right: '30px', top: '10px'}}>
              <IconButton>
                <Lock/>
              </IconButton>
            </Tooltip>
          </>}
          {!!get(errors, name) &&
            <>
              <span className={'validation-error'}>
                {get(errors, name)}
              </span>
            </>
          }
          </div>
      </StyledEditor>
    )

  }, [fieldValue, errors, theme.palette.mode])
}
export default InertiaRichtext
