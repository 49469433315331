import DashboardLayout from "../../layouts/dashboard"
import {trans} from "../../utils/translation"
import {Head} from "@inertiajs/react"
import MailComposeView from "../../sections/log/view/mail-compose-view";

const MailCompose = () => {
  return (
    <>
      <Head>
        <title>{trans('mail.title')}</title>
      </Head>
      <MailComposeView />
    </>
  )
}
MailCompose.layout = (page) => <DashboardLayout>{page}</DashboardLayout>

export default MailCompose