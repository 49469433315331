import {useMemo, useState} from 'react'
import { trans } from '@/utils/translation'
import { Card, Stack, Typography } from '@mui/material'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import { defaultTableOptions } from '@/utils/misc'
import { usePage } from '@inertiajs/react'
import { formatDate } from '@/utils/format'
import DeviceErrorStateLabel from '@/sections/device/device-error-state-label'
import TableRowAction from '../../components/table/table-row-action'
import Iconify from '@/components/iconify'
import { useTheme } from '@mui/material/styles'
import { useBoolean } from '../../hooks/use-boolean'
import TableConfirmDelete from '../../components/table/table-confirm-delete'
import { hasRole } from '../../utils/misc'
import Roles from '../../constants/Roles'

export default function DeviceDataErrorTable() {
  const { device, auth } = usePage().props
  const theme = useTheme()
  const confirmRow = useBoolean()
  const [error, setError] = useState(null)
  const isAdmin = hasRole(auth.user, Roles.ROLE_ADMIN)

  const columns = useMemo(
    () => [
      {
        accessorKey: 'reportDate',
        header: trans('device.error.updated_at'),
        Cell: ({ row, renderedCellValue }) =>
          renderedCellValue ? formatDate(renderedCellValue) : formatDate(row.original.createdAt),

        size: 100,
        enableSorting: false
      },
      {
        accessorKey: 'type',
        header: trans('device.error.type'),
        Cell: ({ renderedCellValue }) => trans(`device.error_type.${renderedCellValue}`),
        size: 300,
        enableSorting: false
      },
      {
        accessorKey: 'state',
        header: trans('field.state'),
        // eslint-disable-next-line react/prop-types
        Cell: ({ renderedCellValue }) => <DeviceErrorStateLabel state={renderedCellValue} />,
        size: 150,
        enableSorting: false
      }
    ],
    []
  )

  const table = useMaterialReactTable({
    ...defaultTableOptions,
    manualFiltering: false,
    manualPagination: false,
    manualSorting: false,
    renderTopToolbar: () => (
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} justifyContent={'space-between'} m={2}>
        <Typography variant="h6"> {trans('device.error.header')} </Typography>
      </Stack>
    ),
    columns,
    data: device.deviceDataErrors,
    enableRowActions: isAdmin,
    renderRowActions: ({ row }) => {
      return (
        <Stack direction={'row'} spacing={1} sx={{ alignItems: 'center' }}>
          <TableRowAction
            row={row}
            width={180}
            items={[
              {
                content: (
                  <>
                    <Iconify icon={'eva:trash-2-outline'} color={theme.palette.error.main} />
                    <Typography variant={'body'}>{trans('button.delete')}</Typography>
                  </>
                ),
                callback: () => {
                  setError(row.original.id)
                  confirmRow.onTrue()
                }
              }
            ]}
          />
        </Stack>
      )
    }
  })

  return (
    <>
      <Card>
        <MaterialReactTable table={table} />
      </Card>
      <TableConfirmDelete url={`/api/device_data_errors/${error}`} confirmRows={confirmRow} setValue={setError} />
    </>
  )
}
