import { useMemo, useState } from 'react'
import { trans } from '@/utils/translation'
import useRemoteTable from '../../hooks/useRemoteTable'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import { defaultTableOptions } from '@/utils/misc'
import { Card, Typography } from '@mui/material'
import { router, usePage } from '@inertiajs/react'
import { COLUMNS, TENANT_COLUMNS } from './table/apartment-table-definitions'
import ApartmentTableToolbar from './table/apartment-table-toolbar'
import PropTypes from 'prop-types'
import Roles from '@/constants/Roles'
import Iconify from '@/components/iconify'
import TableRowAction from '@/components/table/table-row-action'
import { useBoolean } from '@/hooks/use-boolean'
import TableConfirmDelete from '@/components/table/table-confirm-delete'
import { useTheme } from '@mui/material/styles'
import { useModalContext } from '../../context/use-modal-context'
import ApartmentCreateModal from './apartment-create-modal'

export default function ApartmentListTable({ defaultFilter, defaultSorting, searchFields, searchPlaceholder }) {
  const path = '/apartments/list'
  const { auth } = usePage().props
  const theme = useTheme()
  const [apartment, setApartment] = useState(null)
  const confirmRows = useBoolean()
  const { openModal } = useModalContext()

  const columns = useMemo(() => (defaultFilter?.property ? COLUMNS : TENANT_COLUMNS), [])
  const methods = useRemoteTable(path, searchFields, defaultFilter, defaultSorting)
  const {
    globalFilter,
    columnSorting,
    setColumnSorting,
    pagination,
    setPagination,
    data,
    isError,
    isLoading,
    rowCount,
    refresh,
    setRefresh
  } = methods

  const table = useMaterialReactTable({
    ...defaultTableOptions,
    renderTopToolbar: () => <ApartmentTableToolbar {...methods} searchPlaceholder={searchPlaceholder} />,
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        borderRadius: '0'
      }
    },
    enableRowActions: true,
    renderRowActions: ({ row }) => {
        return (
          <TableRowAction
            row={row}
            width={190}
            items={
            [
              {
                content: (
                  <>
                    <Iconify icon={'eva:edit-2-outline'} color={theme.palette.primary.main} />
                    <Typography variant={'body2'}>{trans('table_view.edit_apartment')}</Typography>
                  </>
                ),
                callback: () => openModal(
                  'apartmentCreate',
                  <ApartmentCreateModal apartment={row.original} propertyId={row.original.property.id} setRefresh={setRefresh} />
                ),
              },
                {
                  content: (
                    <>
                      <Iconify icon={'eva:trash-2-outline'} color={theme.palette.error.main} />
                      <Typography variant={'body'}>{trans('button.delete')}</Typography>
                    </>
                  ),
                  callback: () => {
                    setApartment(row.original)
                    confirmRows.onTrue()
                  },
                  disabled: !((row.original.property.state === 'OPEN' || row.original.property.state === 'PROGRESS') &&
                    row.original.property.assignments.length === 1 &&
                    auth.user?.roles?.includes(Roles.ROLE_ADMIN))
                }
            ]}
          />
        )
    },
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        auth.user?.roles?.includes(Roles.ROLE_TENANT)
          ? router.get(`/dashboard/apartment/${row.original.id}`)
          : router.get(`/apartments/${row.original.id}/detail`)
      },
      sx: { cursor: 'pointer' }
    }),
    muiToolbarAlertBannerProps: isError ? { severity: 'error', children: trans('table.data_load_error') } : undefined,
    columns,
    data,
    rowCount: rowCount,
    onPaginationChange: setPagination,
    onSortingChange: setColumnSorting,
    state: {
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isLoading,
      showSkeletons: isLoading,
      sorting: columnSorting,
      refresh
    }
  })

  return (
    <>
      <Card>
        <MaterialReactTable table={table} />
      </Card>

      <TableConfirmDelete
        url={`/api/apartments/${apartment?.id}`}
        confirmRows={confirmRows}
        setValue={setApartment}
        setRefresh={setRefresh}
      />
    </>
  )
}

ApartmentListTable.propTypes = {
  defaultFilter: PropTypes.object,
  defaultSorting: PropTypes.array,
  searchFields: PropTypes.array,
  searchPlaceholder: PropTypes.string
}
