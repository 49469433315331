import { useForm } from '@/hooks/use-form'
import { DialogActions, DialogTitle, Stack } from '@mui/material'
import DialogContent from '@mui/material/DialogContent'
import { FormProvider } from '@/context/use-form-context'
import { ToastCtl } from '@/utils/toast-ctl'
import { router, usePage } from '@inertiajs/react'
import { trans } from '@/utils/translation'
import InertiaDatePicker from '@/components/form/inertia-date-picker'
import InertiaNumericField from '@/components/form/inertia-numeric-field'
import CustomButton from '@/components/custom-button'
import { useModalContext } from '@/context/use-modal-context'
import { LoadingButton } from '@mui/lab'
import InertiaAutocomplete from '@/components/form/inertia-autocomplete'
import InertiaCheckbox from '@/components/form/inertia-checkbox'
import InertiaTextfield from '@/components/form/inertia-textfield'
import { useEffect, useState } from 'react'
import axios from 'axios'
import InertiaRemoteAutocomplete from '@/components/form/inertia-remote-autocomplete'
import { handleErrorResponse } from '@/utils/misc'
import PropTypes from 'prop-types'

export default function BillingAdditionalCostModal({ billingAdditionalCost, billingId }) {
  const { billing } = usePage().props
  const { closeModal } = useModalContext()

  const methods = useForm({
    url: {
      method: billingAdditionalCost?.id ? 'PUT' : 'POST',
      location: billingAdditionalCost?.id
        ? `/api/billing_additional_cost/${billingAdditionalCost.id}`
        : `/api/billing_additional_cost/create`
    },
    defaultValues: {
      additionalCostType: billingAdditionalCost?.additionalCostType?.id,
      additionalCostGroup: 'ALL',
      alternativeCost: billingAdditionalCost?.alternativeCost,
      date: billingAdditionalCost?.date,
      taxRate: billingAdditionalCost?.taxRate || 19,
      grossAmount: billingAdditionalCost?.grossAmount,
      netAmount: billingAdditionalCost?.netAmount,
      levy: billingAdditionalCost?.levy?.id,
      isWork: billingAdditionalCost?.isWork,
      workAmount: billingAdditionalCost?.workAmount,
      billing: billingId,
      users: billingAdditionalCost?.users?.map((item) => item.id),
      apartments: billingAdditionalCost?.apartments?.map((item) => item.id)
    }
  })
  const { submit, submitting, data, setData, setOverrideFieldValues } = methods

  const [groups, setGroups] = useState([
    {
      id: 'ALL',
      label: 'Alle'
    }
  ])
  const [users, setUsers] = useState([])
  const [costTypes, setCostTypes] = useState([])
  const [levies, setLevies] = useState([])
  const [levy, setLevy] = useState([])
  const [groupCostTypes, setGroupCostTypes] = useState([])

  useEffect(() => {
    axios
      .get('/api/additional_cost_types?itemsPerPage=200')
      .then((response) => {
        const items = [
          {
            id: 'ALL',
            label: 'Alle'
          },
          ...response.data['hydra:member']
            .map((item) => ({
              id: item.costGroup,
              label: item.costGroup
            }))
            .reduce((unique, item) => {
              return unique.some((obj) => obj.id === item.id) ? unique : [...unique, item]
            }, [])
            .sort((a, b) => a.label.localeCompare(b.label))
        ]

        setCostTypes(response.data['hydra:member'])
        setGroupCostTypes(response.data['hydra:member'].filter((item) => item.active))
        setGroups(items)
      })
      .catch((e) => {
        console.error(e)
      })

    axios
      .get('/api/levies?itemsPerPage=200')
      .then((response) => {
        const levies = response.data['hydra:member']
        setLevies(levies.filter((item) => item.active))
        setLevy(levies.find((item) => item.id === billingAdditionalCost?.levy?.id))
      })
      .catch((e) => {
        console.error(e)
      })
  }, [])

  useEffect(() => {
    if (levy?.levyKey === '25') {
      axios
        .get(`/billings/${billing.id}/tenants`)
        .then((response) => {
          setUsers(response?.data)
        })
        .catch((e) => {
          console.error(e)
        })
    }
  }, [levy])

  const calculateBrutto = (netto, taxRate) => {
    if (taxRate < 0 || !netto) return

    setData('grossAmount', netto * (1 + taxRate / 100))
    setOverrideFieldValues({ ['grossAmount']: netto * (1 + taxRate / 100) })
  }

  const calculateNetto = (brutto, taxRate) => {
    if (taxRate < 0 || !brutto) return

    setData('netAmount', brutto / (1 + taxRate / 100))
    setOverrideFieldValues({ ['netAmount']: brutto / (1 + taxRate / 100) })
  }

  const handleSubmit = () => {
    submit(
      () => {
        router.reload()
        ToastCtl.show(trans('general.save_success'), 'success')
        closeModal('billingAdditionalCostModal')
      },
      (e) => handleErrorResponse(e)
    )
  }

  return (
    <>
      <DialogTitle> {trans('billing.detail.additional_cost.modal.title')} </DialogTitle>
      <DialogContent dividers sx={{ py: 1 }}>
        <FormProvider {...methods} sx={{ px: 2, mx: 8 }}>
          <form onSubmit={handleSubmit}>
            <Stack direction={'column'} spacing={2}>
              <InertiaAutocomplete
                name={'additionalCostGroup'}
                input={{
                  label: trans('billing.detail.additional_cost.modal.group')
                }}
                items={groups}
                callback={(value) => {
                  if (value && data.additionalCostGroup !== value) {
                    setData('additionalCostType', null)
                    setOverrideFieldValues({ additionalCostType: null })

                    if (value !== 'ALL') {
                      setGroupCostTypes(costTypes.filter((item) => item.costGroup === value && item.active))
                    } else {
                      setGroupCostTypes(costTypes && costTypes.filter((item) => item.active))
                    }
                  }
                }}
              />

              <InertiaAutocomplete
                name={'additionalCostType'}
                input={{
                  label: trans('billing.detail.additional_cost.modal.type')
                }}
                items={groupCostTypes}
                callback={(value) => {
                  if (value) {
                    const costType = costTypes.find((item) => item.id === value)
                    if (!costType) return

                    const levy = levies.find((item) => item.id === costType.levy?.id)
                    if (!levy) return

                    setData('levy', levy?.id)
                    setOverrideFieldValues({
                      levy: levy?.id
                    })
                  }
                }}
              />

              <InertiaTextfield
                name={'alternativeCost'}
                input={{ label: trans('billing.detail.additional_cost.modal.alternative_cost') }}
              />

              <InertiaDatePicker
                name={'date'}
                input={{
                  label: trans('billing.detail.additional_cost.modal.date')
                }}
              />
              <InertiaAutocomplete
                name={'taxRate'}
                input={{ label: trans('billing.detail.additional_cost.modal.tax_rate'), suffix: ' %' }}
                items={[
                  { id: 0, label: '0 %' },
                  { id: 7, label: '7 %' },
                  { id: 19, label: '19 %' },
                  { id: -1, label: 'Andere MwSt. Satz' }
                ]}
                callback={(value) => {
                  if (!data.netAmount) {
                    calculateNetto(data.grossAmount, value)
                  }
                  calculateBrutto(data.netAmount, value)
                }}
              />
              <InertiaNumericField
                name={'grossAmount'}
                input={{
                  label: trans('billing.detail.additional_cost.modal.brutto'),
                  fixedDecimalScale: true,
                  allowNegative: true
                }}
                callback={(value) => calculateNetto(value, data.taxRate)}
              />
              <InertiaNumericField
                name={'netAmount'}
                input={{
                  label: trans('billing.detail.additional_cost.modal.netto'),
                  fixedDecimalScale: true,
                  allowNegative: true
                }}
                callback={(value) => calculateBrutto(value, data.taxRate)}
              />
              <InertiaAutocomplete
                name={'levy'}
                input={{
                  label: trans('billing.detail.additional_cost.modal.levy')
                }}
                items={levies}
                callback={(value) => {
                  if (value) {
                    setLevy(levies.find((item) => item.id === value))
                  }
                }}
              />
              {levy?.levyKey === '25' && (
                <InertiaAutocomplete
                  name={'users'}
                  input={{
                    multiple: true,
                    label: trans('general.user')
                  }}
                  items={users}
                />
              )}
              {levy?.levyKey === '32' && (
                <InertiaRemoteAutocomplete
                  name={'apartments'}
                  input={{
                    multiple: true,
                    label: trans('general.apartment'),
                    tooltip: trans('general.not_all_apartments', { count: 30 })
                  }}
                  defaultValues={billingAdditionalCost?.apartments?.map((item) => ({ id: item.id, label: item.label }))}
                  url={`/api/apartments?property.id=${billing.property?.id}&label=%s&itemsPerPage=30`}
                />
              )}
              <InertiaCheckbox name={'isWork'} input={{ label: trans('billing.detail.additional_cost.modal.work') }} />
              {data.isWork && (
                <InertiaNumericField
                  name={'workAmount'}
                  input={{
                    label: trans('billing.detail.additional_cost.modal.work_amount'),
                    fixedDecimalScale: true
                  }}
                />
              )}
            </Stack>
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <CustomButton onClick={() => closeModal('billingAdditionalCostModal')}> {trans('button.cancel')} </CustomButton>
        <LoadingButton loading={submitting} type="submit" variant="contained" color="primary" onClick={handleSubmit}>
          {trans('button.confirm')}
        </LoadingButton>
      </DialogActions>
    </>
  )
}

BillingAdditionalCostModal.propTypes = {
  billingAdditionalCost: PropTypes.object,
  billingId: PropTypes.string
}
