import Box from '@mui/material/Box';
import { alpha, styled } from '@mui/material/styles';

// ----------------------------------------------------------------------

export const StyledEditor = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  overflow: 'hidden',
  borderRadius: theme.shape.borderRadius,
  border: `solid 1px ${alpha(theme.palette.grey[500], 0.2)}`,
  '& .ql-custom-container': {
    paddingTop: theme.spacing(1),
  },
  '& .ql-container.ql-snow': {
    border: 'none',
    ...theme.typography.body2,
  },
  '& .ql-tooltip': {
    left: '0 !important',
  },
  '& .ql-custom-wrapper': {
    padding: theme.spacing(1, 2),
  },
  '& .ql-snow': {
    ...theme.typography.body2,
    fontFamily: theme.typography.fontFamily,
    border: `solid 1px ${alpha(theme.palette.grey[500], 0.2)}`,
  },
  '& .ql-editor': {
    minHeight: 500,

  },
  '& .ql-toolbar.ql-snow': {
    border: 'none',
    borderBottom: `solid 1px ${alpha(theme.palette.grey[500], 0.2)}`,
    '& .ql-formats': {
      '&:not(:last-of-type)': {
        marginRight: theme.spacing(2),
      },
    },
  },
}));

