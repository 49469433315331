import { DialogActions, DialogTitle, Grid } from '@mui/material'
import { trans } from '@/utils/translation'
import DialogContent from '@mui/material/DialogContent'
import CustomButton from '@/components/custom-button'
import { LoadingButton } from '@mui/lab'
import { useModalContext } from '@/context/use-modal-context'
import { useForm } from '@/hooks/use-form'
import { router, usePage } from '@inertiajs/react'
import { ToastCtl } from '@/utils/toast-ctl'
import useSubmit from '@/hooks/use-submit'
import { FormProvider } from '@/context/use-form-context'
import PropTypes from 'prop-types'
import InertiaAutocomplete from '@/components/form/inertia-autocomplete'
import InertiaRemoteAutocomplete from '@/components/form/inertia-remote-autocomplete'
import InertiaDatePicker from '@/components/form/inertia-date-picker'
import { hasRole } from '@/utils/misc'
import Roles from '@/constants/Roles'

export const billingCostTypes = [
  {
    id: 'enableHeatingCost',
    label: trans('property.billing_setting.charged_costs.heating')
  },
  {
    id: 'enableWarmWaterCost',
    label: trans('property.billing_setting.charged_costs.warm_water')
  },
  {
    id: 'enableColdWaterCost',
    label: trans('property.billing_setting.charged_costs.cold_water')
  },
  {
    id: 'enableAdditionalCost',
    label: trans('property.billing_setting.charged_costs.additional_cost')
  }
]

const costPercentage = [
  {
    id: 30,
    label: trans('property.billing_setting.charged_costs.30_70_percent')
  },
  {
    id: 50,
    label: trans('property.billing_setting.charged_costs.50_50_percent')
  },
  {
    id: 40,
    label: trans('property.billing_setting.charged_costs.40_60_percent')
  },
  {
    id: 100,
    label: trans('property.billing_setting.charged_costs.100_0_percent')
  },
  {
    id: 0,
    label: trans('property.billing_setting.charged_costs.0_100_percent')
  }
]

export default function PropertyEditBillingSettingsModal({ property }) {
  const { auth } = usePage().props
  const { closeModal } = useModalContext()

  const enabledValues = []
  if (property.billingSettings.enableHeatingCost) {
    enabledValues.push('enableHeatingCost')
  }
  if (property.billingSettings.enableWarmWaterCost) {
    enabledValues.push('enableWarmWaterCost')
  }
  if (property.billingSettings.enableColdWaterCost) {
    enabledValues.push('enableColdWaterCost')
  }
  if (property.billingSettings.enableAdditionalCost) {
    enabledValues.push('enableAdditionalCost')
  }

  const methods = useForm({
    url: {
      method: 'PUT',
      location: `/api/properties/${property.id}/edit-billing-settings`
    },
    defaultValues: {
      enabledCostTypes: enabledValues,
      heatingBaseCostPercentage: property.billingSettings.heatingBaseCostPercentage,
      warmWaterBaseCostPercentage: property.billingSettings.warmWaterBaseCostPercentage,
      heatingBaseCostLevy: property.billingSettings.heatingBaseCostLevy?.id,
      warmWaterBaseCostLevy: property.billingSettings.warmWaterBaseCostLevy?.id,
      warmWaterUsageCostLevy: property.billingSettings.warmWaterUsageCostLevy?.id,
      reportDate: property.billingSettings.reportDate
    }
  })

  const { submit, submitting } = methods

  const handleSubmit = () => {
    submit(() => {
      router.reload()
      ToastCtl.show(trans('general.save_success'), 'success')
      closeModal('propertyEditBillingSettings')
    })
  }

  useSubmit(() => handleSubmit())

  return (
    <>
      <DialogTitle>{trans('property.billing_setting.edit')}</DialogTitle>
      <DialogContent dividers sx={{ py: 1 }}>
        <FormProvider {...methods} sx={{ px: 2, mx: 8 }}>
          <form onSubmit={handleSubmit}>
            <Grid container direction={'row'} spacing={2}>
              <Grid item xs={12}>
                <InertiaAutocomplete
                  name={'enabledCostTypes'}
                  input={{
                    label: trans('property.billing_setting.charged_costs.title'),
                    multiple: true
                  }}
                  items={billingCostTypes}
                />
              </Grid>
              <Grid item xs={12}>
                <InertiaAutocomplete
                  name={'heatingBaseCostPercentage'}
                  input={{
                    label: trans('property.billing_setting.heating_cost_split')
                  }}
                  items={costPercentage}
                />
              </Grid>
              <Grid item xs={12}>
                <InertiaAutocomplete
                  name={'warmWaterBaseCostPercentage'}
                  input={{
                    label: trans('property.billing_setting.warm_water_cost_split')
                  }}
                  items={costPercentage}
                />
              </Grid>
              <Grid item xs={12}>
                <InertiaRemoteAutocomplete
                  name={'heatingBaseCostLevy'}
                  input={{
                    label: trans('field.property.heating_base_cost_levy')
                  }}
                  url={'/api/levies?name=%s&itemsPerPage=50'}
                />
              </Grid>
              <Grid item xs={12}>
                <InertiaRemoteAutocomplete
                  name={'warmWaterBaseCostLevy'}
                  input={{
                    label: trans('field.property.warm_water_base_cost_levy')
                  }}
                  url={'/api/levies?name=%s&itemsPerPage=50'}
                />
              </Grid>
              <Grid item xs={12}>
                <InertiaRemoteAutocomplete
                  name={'warmWaterUsageCostLevy'}
                  input={{
                    label: trans('field.property.warm_water_usage_cost_levy')
                  }}
                  url={'/api/levies?name=%s&itemsPerPage=50'}
                />
              </Grid>
              <Grid item xs={12}>
                <InertiaDatePicker
                  name={'reportDate'}
                  input={{
                    label: trans('field.property.report_date'),
                    format: 'DD.MM',
                    views: ['day', 'month'],
                    disabled: !hasRole(auth.user, Roles.ROLE_ADMIN)
                  }}
                />
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <CustomButton onClick={() => closeModal('propertyEditBillingSettings')}>{trans('button.cancel')}</CustomButton>
        <LoadingButton
          loading={submitting}
          variant={'contained'}
          onClick={() => {
            handleSubmit()
          }}
        >
          {trans('button.done')}
        </LoadingButton>
      </DialogActions>
    </>
  )
}

PropertyEditBillingSettingsModal.propTypes = {
  property: PropTypes.object
}
