import { trans } from '@/utils/translation'
import UserCreateModal from '@/sections/user/user-create-modal'
import InertiaRemoteAutocomplete from '@/components/form/inertia-remote-autocomplete'
import { useFormContext } from '@/context/use-form-context'
import { useModalContext } from '@/context/use-modal-context'
import PropTypes from 'prop-types'

export default function UserRemoteCreateSelect({ label, url, type = 'tenant', apartment, apartmentIndex=null, defaultValue, ...options }) {
  const { openModal } = useModalContext()
  const { setData, setOverrideFieldValues, data } = useFormContext()

  return (
    <InertiaRemoteAutocomplete
      name={'user'}
      input={{
        label,
        tooltip: trans('general.not_all_users', { count: 30 }),
      }}
      {...options}
      defaultValue={defaultValue}
      defaultItem={{ id: 'NEW', label: `+ ${trans('user.create')}` }}
      url={url ?? '/api/users?name=%s&itemsPerPage=30'}
      callback={(selected) => {
        if (selected === 'NEW') {
          openModal(
            'userCreate',
            <UserCreateModal
              type={type}
              apartment={apartment}
              callback={(response) => {
                if(response && data.apartments && apartmentIndex !== null) {
                  setData(`apartments.${apartmentIndex}.user`, response.data.id)
                  setOverrideFieldValues({
                    [`apartments.${apartmentIndex}.user`]: {
                      id: response.data.id,
                      label: response.data.name
                    }
                  })
                }
                else if (response?.data?.id) {
                  setData('user', response.data.id)
                  setOverrideFieldValues({
                    user: {
                      id: response.data.id,
                      label: response.data.name
                    }
                  })
                } else {
                  setData('user', null)
                  setOverrideFieldValues({
                    user: {
                      id: null,
                      label: ''
                    }
                  })
                }
              }}
            />
          )
        }
      }}
    />
  )
}

UserRemoteCreateSelect.propTypes = {
  label: PropTypes.string,
  url: PropTypes.string,
  type: PropTypes.string,
  apartment: PropTypes.object
}
