import { Card, DialogActions, DialogTitle, Grid, Stack, Typography } from '@mui/material'
import { trans } from '../../../utils/translation'
import InertiaTextfield from '../../../components/form/inertia-textfield'
import { FormProvider } from '../../../context/use-form-context'
import { ToastCtl } from '../../../utils/toast-ctl'
import useSubmit from '../../../hooks/use-submit'
import CustomButton from '../../../components/custom-button'
import Button from '@mui/material/Button'
import DialogContent from '@mui/material/DialogContent'
import { LoadingButton, Pagination } from '@mui/lab'
import { useModalContext } from '../../../context/use-modal-context'
import MailRecipientModal from '../mail-recipient-modal'
import MailRecipient from '../mail-recipient'
import { useForm } from '@/hooks/use-form'
import { useEffect, useState } from 'react'
import InertiaRichtext from '../../../components/form/inertia-richtext'
import { ConfirmDialog } from '../../../components/custom-dialog'
import { useBoolean } from '../../../hooks/use-boolean'
import { useTheme } from '@mui/material/styles'
import { formatDate } from '../../../utils/format'

export default function MailComposeView() {
  const { openModal } = useModalContext()
  const theme = useTheme()

  const [recipients, setRecipients] = useState([])
  const [errors, setErrors] = useState(null)
  const [sendDate, setSendDate] = useState(null)
  const confirm = useBoolean()
  const isSent = useBoolean()
  const [page, setPage] = useState(1)
  const indexOfLastRecipient = page * 10
  const indexOfFirstRecipient = indexOfLastRecipient - 10
  const pageRecipients = recipients.slice(indexOfFirstRecipient, indexOfLastRecipient)
  
  const methods = useForm({
    url: {
      method: 'PUT',
      location: '/api/mails/send'
    },
    defaultValues: {
      subject: '',
      content: '',
      recipients: recipients,
      isTestEmail: false
    }
  })

  const { setData, submit, submitting } = methods

  useEffect(() => {
    setData('recipients', recipients)
  }, [recipients])

  useEffect(() => {
    if (methods.errors) {
      setErrors(methods.errors?.recipients)
    }
  }, [methods.errors])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleSubmit = (isTestEmail = false) => {
    submit(
      (response) => {
        if (response.data) {
          ToastCtl.show(trans('general.email_success'), 'success')
          if (!isTestEmail) {
            isSent.onTrue()
            setSendDate(new Date())
          }
        }
      },
      null,
      { data: { isTestEmail } }
    )
  }

  useSubmit(() => handleSubmit())

  const renderMailCompose = (
    <>
      <Card sx={{ p: 3 }}>
        <Stack spacing={3} direction={'column'} mb={2}>
          <InertiaTextfield
            name={'subject'}
            input={{
              label: trans('mail.subject'),
              required: true
            }}
          />
          <InertiaRichtext
            name={'content'}
            input={{
              required: true,
              label: trans('mail.content')
            }}
            sx={{ p: 2 }}
          />
        </Stack>
      </Card>
    </>
  )

  const renderMailRecipients = (
    <>
      <Card sx={{ p: 1 }}>
        <DialogTitle>{trans('mail.recipient')}</DialogTitle>
        <DialogContent sx={{ mb: 2 }}>
          <Stack spacing={2} direction={'column'} mb={4}>
            {errors && (
              <Typography variant={'body2'} sx={{ color: theme.palette.error.main }}>
                {errors}
              </Typography>
            )}

            {pageRecipients.map((recipient) => (
              <MailRecipient key={recipient.id} recipient={recipient} setRecipients={setRecipients} />
            ))}

            {recipients.length > 10 && (
            <Pagination
              count={Math.ceil(recipients.length / 10)}
              page={page}
              onChange={handleChangePage}
              color="primary"
              />
            )}
          </Stack>

          {isSent.value && (
            <Typography
              variant={'body2'}
              sx={{
                color: theme.palette.success.main,
                backgroundColor: theme.palette.success.lighter,
                borderRadius: 1,
                p: 1
              }}
            >
              Wurde am {formatDate(sendDate)} gesendet.
            </Typography>
          )}

          {!isSent.value && (
            <Stack spacing={2} alignItems={'flex-start'}>
              <Button
                variant="contained"
                color={'primary'}
                sx={{ mr: 4, display: 'block' }}
                onClick={() =>
                  openModal(
                    'mailRecipient',
                    <MailRecipientModal setRecipients={setRecipients} recipients={recipients} />
                  )
                }
              >
                + {trans('mail.add_recipient')}
              </Button>
              <Button variant="contained" color={'error'} sx={{ mr: 4 }} onClick={() => setRecipients([])}>
                {trans('mail.delete_all')}
              </Button>
            </Stack>
          )}
        </DialogContent>
        <ConfirmDialog
          open={confirm.value}
          onClose={confirm.onFalse}
          title={trans('mail.confirm')}
          content={
            <>
              Sind Sie sicher diese E-Mail an E-Mail Adressen zu senden zu wollen? Klicken Sie auf Bestätigen, um den
              Mailversand zu starten. Eine Rückholung der E-Mails ist dann nicht mehr möglich.
            </>
          }
          action={
            <>
              <LoadingButton
                loading={submitting}
                variant={'contained'}
                onClick={() => {
                  handleSubmit()
                  confirm.onFalse()
                }}
              >
                {trans('button.confirm')}
              </LoadingButton>
            </>
          }
        />
        {!isSent.value && (
          <DialogActions mt={2}>
            <CustomButton
              onClick={() => {
                handleSubmit(true)
              }}
            >
              {trans('mail.test')}
            </CustomButton>
            <LoadingButton
              loading={submitting}
              variant={'contained'}
              onClick={() => {
                confirm.onTrue()
              }}
            >
              {trans('button.send')}
            </LoadingButton>
          </DialogActions>
        )}
      </Card>
    </>
  )

  return (
    <>
      <Typography variant={'h5'} gutterBottom>
        {trans('mail.title')}
      </Typography>

      <FormProvider {...methods} sx={{ px: 2, mx: 8 }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={4}>
            <Grid item xs={12} xl={8} lg={12}>
              {renderMailCompose}
            </Grid>

            <Grid item xs={12} xl={4} lg={12}>
              {renderMailRecipients}
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </>
  )
}
