import {Card, CardHeader, Grid, Stack} from '@mui/material'
import { trans } from '@/utils/translation'
import Typography from '@mui/material/Typography'
import { FormProvider } from '@/context/use-form-context'
import { useForm } from '@/hooks/use-form'
import { LoadingButton } from '@mui/lab'
import useSubmit from '@/hooks/use-submit'
import { router, usePage } from '@inertiajs/react'
import { ToastCtl } from '@/utils/toast-ctl'
import dayjs from 'dayjs'
import Box from '@mui/material/Box'
import InertiaDatePicker from '../../components/form/inertia-date-picker'
import UserRemoteCreateSelect from '../../components/user-remote-create-select'

export default function PropertyCompleteStep3_0() {
  const { property } = usePage().props

  const methods = useForm({
    url: {
      method: 'PUT',
      location: `/api/properties/${property.id}/step-3-0`
    },
    defaultValues: {
      apartments: property.apartments.map((apartment) => ({
        id: apartment.id,
        begin: apartment.owner?.begin || dayjs().subtract(1, 'year').startOf('year').set('hour', 12),
        user: apartment.owner?.user.id || null
      }))
    }
  })

  const { submit, submitting, data, setData, setOverrideFieldValues, setError } = methods

  const handleSubmit = () => {
    submit((response) => {
      router.get(`/properties/${response.data.id}/complete/step-3`)
      ToastCtl.show(trans('general.save_success'), 'success')
    })
  }

  useSubmit(() => handleSubmit())

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit}>
        <Card sx={{ maxWidth: '750px', mx: 'auto' }}>
          <CardHeader sx={{ mb: 2 }} title={trans('initial_setup.owner_info.header')} />
          {property.apartments.map((apartment, index) => (
            <Box key={index}>
              <Typography variant={'body2'} sx={{ ml: 3, mt: 2, mb: 1 }}>
                {apartment.label}
              </Typography>

              <Stack direction="column" spacing={{ xs: 2 }} sx={{ mx: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                  <InertiaDatePicker
                    name={`apartments.${index}.begin`}
                    input={{
                      label: trans('initial_setup.owner_info.begin'),
                      disabled: data.apartments[index].isEmpty
                    }}
                  />
                  </Grid>
                  <Grid item xs={12} md={8}>
                  <UserRemoteCreateSelect
                    type={'owner'}
                    name={`apartments.${index}.user`}
                    apartment={apartment}
                    input={{
                      label: trans('initial_setup.owner_info.owner'),
                      required: true,
                    }}
                    apartmentIndex={index}
                    defaultValue={apartment.owner ? { id: apartment.owner.user.id, label: apartment.owner.user.name } : null}
                  />
                  </Grid>
                  </Grid>
              </Stack>
            </Box>
          ))}
          <Stack direction={'row'} spacing={2} sx={{ justifyContent: 'flex-end', mx: 3, my: 3 }}>
            <LoadingButton
              variant={'outlined'}
              onClick={() => router.get(`/properties/${property.id}/complete/step-2`)}
            >
              {trans('button.back')}
            </LoadingButton>
            <LoadingButton variant={'contained'} loading={submitting} onClick={handleSubmit}>
              {trans('button.next')}
            </LoadingButton>
          </Stack>
        </Card>
      </form>
    </FormProvider>
  )
}