import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepButton from '@mui/material/StepButton'
import { LoadingButton } from '@mui/lab'
import { trans } from '@/utils/translation'
import { Card, Stack } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { router, usePage } from '@inertiajs/react'
import Typography from '@mui/material/Typography'
import PropertyCompleteStep1 from '@/sections/property-complete/property-complete-step-1'
import PropertyCompleteStep2 from '@/sections/property-complete/property-complete-step-2'
import PropertyCompleteStep3 from '@/sections/property-complete/property-complete-step-3'
import PropertyCompleteStep4 from '@/sections/property-complete/property-complete-step-4'
import PropertyCompleteStep5 from '../property-complete-step-5'
import Roles from '../../../constants/Roles'
import { hasRole } from '../../../utils/misc'
import { useState } from 'react'
import { FormProvider } from '../../../context/use-form-context'
import InertiaToggleButton from '../../../components/form/inertia-toggle-button'
import useSubmit from '../../../hooks/use-submit'
import { ToastCtl } from '../../../utils/toast-ctl'
import { useForm } from '@/hooks/use-form'
import PropertyCompleteStep3_0 from '../property-complete-step-3-0'

const steps = [1, 2, 3, 4, 5]

export default function PropertyCompleteView() {
  const { property, step, auth } = usePage().props
  const theme = useTheme()
  const isAdminOrManager = hasRole(auth.user, Roles.ROLE_ADMIN) || hasRole(auth.user, Roles.ROLE_PROPERTY_ADMIN)
  const [isWeg, setIsWeg] = useState(false)

  const methods = useForm({
    url: {
      method: 'PUT',
      location: `/api/properties/${property.id}/step-0`
    },
    defaultValues: {
      isWEG: property.isWEG ?? false
    }
  })

  const { submit, submitting } = methods

  const handleSubmit = () => {
    submit((response) => {
      router.get(`/properties/${response.data.id}/complete/step-1`)
      ToastCtl.show(trans('general.save_success'), 'success')
    })
  }

  useSubmit(() => handleSubmit())

  const renderOverview = (
    <Card sx={{ maxWidth: '400px', mx: 'auto' }}>
      <Box sx={{ my: 2, py: 1, mx: 3 }}>
        <Typography variant={'h5'}>{trans('initial_setup.title')}</Typography>
        <Box sx={{ my: 1 }}>
          <Typography variant={'h6'}>{trans('initial_setup.property')}</Typography>
          <Typography variant={'subtitle2'}>
            {property?.address?.street} {property?.address?.streetNumber}
          </Typography>
          <Typography variant={'subtitle2'}>
            {property?.address?.postcode} {property?.address?.city}
          </Typography>
        </Box>

        <Box sx={{ my: 2 }}>
          <Typography variant={'h6'} sx={{ my: 1 }}>
            {trans('initial_setup.importance_title')}
          </Typography>
          <Typography variant={'body2'}>{trans('initial_setup.importance_description')}</Typography>
        </Box>

        <Stack direction={'row'} spacing={2} sx={{ mt: 3, justifyContent: 'flex-end' }}>
          <LoadingButton variant={'outlined'} onClick={() => router.get(`/properties/${property.id}/detail`)}>
            {trans('button.back')}
          </LoadingButton>
          <LoadingButton
            variant={'contained'}
            onClick={() => {
              if (isAdminOrManager) {
                setIsWeg(true)
              } else {
                router.get(`/properties/${property.id}/complete/step-1`)
              }
            }}
          >
            {trans('initial_setup.start')}
          </LoadingButton>
        </Stack>
      </Box>
    </Card>
  )

  const renderIsWeg = (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit}>
        <Card sx={{ maxWidth: '400px', mx: 'auto' }}>
          <Box sx={{ my: 2, py: 1, mx: 3 }}>
            <Typography variant={'h6'}>{trans('initial_setup.is_weg.question')}</Typography>
            <InertiaToggleButton
              name={'isWEG'}
              input={{
                label: ''
              }}
              left={{
                label: trans('initial_setup.is_weg.yes')
              }}
              right={{
                label: trans('initial_setup.is_weg.no')
              }}
              leftOptions={{
                mr: 0
              }}
              options={{
                flexDirection: 'column',
                gap: 2
              }}
            />
            <Typography variant={'h6'}> {trans('initial_setup.is_weg.why')}:</Typography>
            <Typography variant={'body2'}> {trans('initial_setup.is_weg.description')}</Typography>

            <Stack direction={'row'} spacing={2} sx={{ mt: 3, justifyContent: 'flex-end' }}>
              <LoadingButton variant={'outlined'} onClick={() => setIsWeg(false)}>
                {trans('button.back')}
              </LoadingButton>
              <LoadingButton variant={'contained'} onClick={handleSubmit} loading={submitting}>
                {trans('button.next')}
              </LoadingButton>
            </Stack>
          </Box>
        </Card>
      </form>
    </FormProvider>
  )

  return (
    <Box>
      <Stepper
        nonLinear
        activeStep={step === '3-0' ? 2 : parseInt(step) - 1}
        sx={{
          justifyContent: 'space-between',
          mb: 3,
          maxWidth: '400px',
          mx: 'auto',
          '& .MuiStepLabel-root .Mui-completed': {
            color: theme.palette.success.main // circle color (COMPLETED)
          }
        }}
      >
        {steps.map((label, index) => (
          <Step
            key={label}
            disabled={parseInt(step) === 30 ? index > 2 : index > parseInt(step) - 1}
            completed={step === '3-0' ? index < 2 : index < parseInt(step) - 1}
            onClick={() => {
              if (index > Number(step) - 1) return

              router.get(`/properties/${property.id}/complete/step-${label}`)
            }}
          >
            <StepButton color="inherit"></StepButton>
          </Step>
        ))}
      </Stepper>
      <Box>
        {
          <Box sx={{ mt: 2, mb: 1, py: 1 }}>
            {parseInt(step) === 0 && !isWeg && renderOverview}
            {parseInt(step) === 0 && isWeg && renderIsWeg}
            {parseInt(step) === 1 && <PropertyCompleteStep1 />}
            {parseInt(step) === 2 && <PropertyCompleteStep2 />}
            {parseInt(step) === 3 && step !== '3-0' && <PropertyCompleteStep3 />}
            {parseInt(step) === 4 && <PropertyCompleteStep4 />}
            {parseInt(step) === 5 && <PropertyCompleteStep5 />}
            {step === '3-0' && <PropertyCompleteStep3_0 />}
          </Box>
        }
      </Box>
    </Box>
  )
}
