import { DialogActions, DialogTitle, Grid } from '@mui/material'
import DialogContent from '@mui/material/DialogContent'
import { FormProvider } from '@/context/use-form-context'
import InertiaDatePicker from '@/components/form/inertia-date-picker'
import { useModalContext } from '@/context/use-modal-context'
import { trans } from '@/utils/translation'
import CustomButton from '@/components/custom-button'
import { LoadingButton } from '@mui/lab'
import PropTypes from 'prop-types'
import { useForm } from '@/hooks/use-form'
import { ToastCtl } from '@/utils/toast-ctl'
import useSubmit from '@/hooks/use-submit'
import dayjs from 'dayjs'

export default function ApartmentGenetrateUviModal({ apartment }) {
  const { closeModal } = useModalContext()

  const methods = useForm({
    url: {
      method: 'POST'
    },
    defaultValues: {
      uviMonth: dayjs().subtract(1, 'month')
    }
  })

  const { data, submit, submitting } = methods

  const handleSubmit = () => {
    const url = `/apartments/${apartment.id}/create-uvi/${data.uviMonth}`
    submit(
      (response) => {
        const pdfUrl = response.data?.pdfUrl
        if (pdfUrl) {
          ToastCtl.show(trans('general.data_export_hint'), 'info')
          window.location.href = pdfUrl
        } else {
          ToastCtl.show(trans('dashboard.consumption.data_empty'), 'error')
        }
        closeModal()
      },
      (error) => {
        ToastCtl.show(error, 'error')
      },
      { url: url }
    )
  }

  useSubmit(() => handleSubmit())

  return (
    <>
      <DialogTitle>{trans('apartment.admin_function.uvi_generate')}</DialogTitle>
      <DialogContent dividers sx={{ py: 1 }}>
        <FormProvider {...methods} sx={{ px: 2, mx: 8 }}>
          <form onSubmit={handleSubmit}>
            <Grid container direction={'row'} spacing={2}>
              <Grid item xs={12}>
                <InertiaDatePicker
                  name={'uviMonth'}
                  input={{
                    label: 'Monat',
                    views: ['month', 'year']
                  }}
                />
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <CustomButton onClick={() => closeModal('apartmentUvi')}>{trans('button.cancel')}</CustomButton>
        <LoadingButton onClick={handleSubmit} loading={submitting} variant="contained" color="primary">
          {trans('button.confirm')}
        </LoadingButton>
      </DialogActions>
    </>
  )
}

ApartmentGenetrateUviModal.propTypes = {
  apartment: PropTypes.object.isRequired
}
