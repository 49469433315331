import { useMemo, useState } from 'react'
import { trans } from '@/utils/translation'
import { Card, Stack } from '@mui/material'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import { defaultTableOptions } from '@/utils/misc'
import Typography from '@mui/material/Typography'
import CustomButton from '@/components/custom-button'
import MenuItem from '@mui/material/MenuItem'
import Iconify from '@/components/iconify'
import CreateModal from '@/sections/apartment-tenant/create-modal'
import { useModalContext } from '@/context/use-modal-context'
import { router, usePage } from '@inertiajs/react'
import { formatDate } from '@/utils/format'
import TableConfirmDelete from '@/components/table/table-confirm-delete'
import { useBoolean } from '@/hooks/use-boolean'

export default function ApartmentsTenantsListTable() {
  const { apartment } = usePage().props
  const { openModal } = useModalContext()
  const confirmRow = useBoolean()
  const [apartmentTenant, setApartmentTenant] = useState(null)

  const columns = useMemo(
    () => [
      {
        accessorKey: 'user.name',
        header: trans('general.tenant'),
        Cell: ({ renderedCellValue }) => (renderedCellValue ? renderedCellValue : trans('apartment.no_tenant')),
        size: 250,
        enableSorting: false,
        muiTableBodyCellProps: ({ row }) => ({
          onClick: () => {
            router.get(`/tenants/${row.original.user?.id}/detail`)
          },
          sx: {
            cursor: 'pointer',
            '&:hover': {
              textDecoration: 'underline'
            }
          }
        })
      },
      {
        accessorKey: 'begin',
        header: trans('general.from'),
        Cell: ({ renderedCellValue }) => formatDate(renderedCellValue),
        size: 50,
        enableSorting: false
      },
      {
        accessorKey: 'end',
        header: trans('general.to'),
        Cell: ({ renderedCellValue }) => (renderedCellValue ? formatDate(renderedCellValue) : '-'),
        size: 50,
        enableSorting: false
      }
    ],
    []
  )

  const table = useMaterialReactTable({
    ...defaultTableOptions,
    renderTopToolbar: () => (
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} justifyContent={'space-between'} m={2}>
        <Typography variant="h6"> {trans('apartment.tenants.title')} </Typography>
        <CustomButton
          variant="contained"
          color="primary"
          onClick={() =>
            openModal(
              'apartmentTenantCreate',
              <CreateModal
                defaultValues={{ property: apartment.property.id, apartments: [apartment.id], apartment: apartment }}
              />
            )
          }
        >
          + {trans('apartment_tenant.create')}{' '}
        </CustomButton>
      </Stack>
    ),
    columns,
    data: apartment.tenants,
    enableRowActions: true,
    renderRowActionMenuItems: ({ row }) => [
      <MenuItem
        key="editUser"
        disabled={!row.original.user?.id}
        onClick={() => router.get(`/tenants/${row.original.user?.id}/detail`)}
      >
        <Iconify icon="solar:pen-bold" sx={{ mr: 1 }} />
        {trans('table_view.edit_user')}
      </MenuItem>,
      <MenuItem
        key="editDate"
        onClick={() =>
          openModal(
            'apartmentTenantCreate',
            <CreateModal
              defaultValues={{
                id: row.original.id,
                user: row.original.user?.id,
                apartments: [apartment.id],
                property: apartment.property.id,
                begin: row.original.begin,
                end: row.original.end,
                isEmpty: !row.original.user?.id
              }}
            />
          )
        }
      >
        <Iconify icon="solar:pen-bold" sx={{ mr: 1 }} />
        {trans('table_view.edit_time')}
      </MenuItem>,
      <MenuItem
        key="delete"
        sx={{ color: 'error.main' }}
        onClick={() => {
          setApartmentTenant(row.original)
          confirmRow.onTrue()
        }}
      >
        <Iconify icon="solar:trash-bin-trash-bold" sx={{ mr: 1 }} />
        {trans('table_view.delete_time')}
      </MenuItem>
    ]
  })

  return (
    <>
      <Card>
        <MaterialReactTable table={table} />
      </Card>

      <TableConfirmDelete
        url={`/api/apartment_tenants/${apartmentTenant?.id}`}
        confirmRows={confirmRow}
        setValue={setApartmentTenant}
      />
    </>
  )
}
