import {useTheme} from "@mui/material/styles";
import {ListItem, ListItemIcon} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import ListItemText from "@mui/material/ListItemText";
import {LoadingButton} from "@mui/lab";
import Iconify from "../../components/iconify";
import PropTypes from "prop-types";
import {avatar} from "../../utils/misc";
import Box from "@mui/material/Box";

export default function MailRecipient({ recipient, setRecipients }) {

  const theme = useTheme()

  return (
    <Box sx={{ border: 1, borderColor: 'divider', borderRadius: 1}}>
      <ListItem sx={{overflow: 'auto'}}>
        <ListItemIcon>
          <Avatar sx={{ width: 24, height: 24, mr: 1 }} src={avatar(recipient)} />
        </ListItemIcon>
        <ListItemText
          primary={recipient.name}
          secondary={recipient.email}
          primaryTypographyProps={{ noWrap: false, typography: 'subtitle1' }}
          secondaryTypographyProps={{ noWrap: false, typography: 'caption'}}
          sx={{ flexGrow: 1 }}
        />
        <LoadingButton
          size="small"
          endIcon={
            <Iconify
              width={20}
              icon="solar:trash-bin-trash-bold"
              color={theme.palette.error.main}
            />
          }
          onClick={() => {
            setRecipients((recipients) => recipients.filter((item) => item.id !== recipient.id))}}
        ></LoadingButton>
      </ListItem>
    </Box>
  )
}

MailRecipient.propTypes = {
  recipient: PropTypes.object.isRequired,
  setRecipients: PropTypes.func.isRequired
}