import { Card, CardHeader, Grid, Stack } from '@mui/material'
import { trans } from '@/utils/translation'
import Typography from '@mui/material/Typography'
import { FormProvider } from '@/context/use-form-context'
import { useForm } from '@/hooks/use-form'
import { LoadingButton } from '@mui/lab'
import useSubmit from '@/hooks/use-submit'
import { router, usePage } from '@inertiajs/react'
import { ToastCtl } from '@/utils/toast-ctl'
import dayjs from 'dayjs'
import Box from '@mui/material/Box'
import InertiaDatePicker from '../../components/form/inertia-date-picker'
import InertiaSwitch from '../../components/form/inertia-switch'
import UserRemoteCreateSelect from '../../components/user-remote-create-select'
import { hasRole } from '../../utils/misc'
import Roles from '../../constants/Roles'

export default function PropertyCompleteStep3() {
  const { property, auth } = usePage().props
  const isAdminOrManager = hasRole(auth.user, Roles.ROLE_ADMIN) || hasRole(auth.user, Roles.ROLE_PROPERTY_ADMIN)

  const methods = useForm({
    url: {
      method: 'PUT',
      location: `/api/properties/${property.id}/step-3`
    },
    defaultValues: {
      apartments: property.apartments.map((apartment) => ({
        id: apartment.id,
        begin: apartment.tenant?.begin || dayjs().subtract(1, 'year').startOf('year').set('hour', 12),
        isEmpty: !apartment.tenant || false,
        user: apartment.tenant?.user.id || null
      }))
    }
  })

  const { submit, submitting, data, setData, setOverrideFieldValues, setError } = methods

  const handleSubmit = () => {
    submit((response) => {
      router.get(`/properties/${response.data.id}/complete/step-4`)
      ToastCtl.show(trans('general.save_success'), 'success')
    })
  }

  useSubmit(() => handleSubmit())

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit}>
        <Card sx={{ maxWidth: '750px', mx: 'auto' }}>
          <CardHeader sx={{ mb: 2 }} title={trans('initial_setup.tenant_info.header')} />
          {property.apartments.map((apartment, index) => (
            <Box key={index}>
              <Typography variant={'body2'} sx={{ ml: 3, mt: 2, mb: 1 }}>
                {apartment.label}
              </Typography>

              <Stack direction="column" spacing={{ xs: 2 }} sx={{ mx: 3 }}>
                <Box sx={{ maxWidth: '170px' }}>
                  <InertiaSwitch
                    name={`apartments.${index}.isEmpty`}
                    input={{
                      label: trans('apartment_tenant.is_empty')
                    }}
                    callback={(value) => {
                      if (value) {
                        setData(`apartments.${index}.tenant.user`, null)

                        setOverrideFieldValues({
                          [`apartments.${index}.user`]: null
                        })

                        setError(`apartments.${index}.user`, null)
                      } else {
                        // Reset override, so on next change we can reset the user
                        setOverrideFieldValues({
                          [`apartments.${index}.user`]: null
                        })
                      }
                    }}
                  />
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <InertiaDatePicker
                      name={`apartments.${index}.begin`}
                      input={{
                        label: trans('initial_setup.tenant_info.begin'),
                        disabled: data.apartments[index].isEmpty
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <UserRemoteCreateSelect
                      type={'tenant'}
                      name={`apartments.${index}.user`}
                      apartment={apartment}
                      input={{
                        label: trans('initial_setup.tenant_info.tenant'),
                        required: true,
                        disabled: data.apartments[index].isEmpty
                      }}
                      apartmentIndex={index}
                      defaultValue={
                        apartment.tenant ? { id: apartment.tenant.user.id, label: apartment.tenant.user.name } : null
                      }
                    />
                  </Grid>
                </Grid>
              </Stack>
            </Box>
          ))}
          <Stack direction={'row'} spacing={2} sx={{ justifyContent: 'flex-end', mx: 3, my: 3 }}>
            <LoadingButton
              variant={'outlined'}
              onClick={() => {
                if (isAdminOrManager && property.isWEG) {
                  router.get(`/properties/${property.id}/complete/step-3-0`)
                } else {
                  router.get(`/properties/${property.id}/complete/step-2`)
                }
              }}
            >
              {trans('button.back')}
            </LoadingButton>
            <LoadingButton variant={'contained'} loading={submitting} onClick={handleSubmit}>
              {trans('button.next')}
            </LoadingButton>
          </Stack>
        </Card>
      </form>
    </FormProvider>
  )
}
